import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { useTransacoesPage } from '@/lib/composables/transacao/useTransacoesPage';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IExtratoItem, TransacaoTipo } from '@/typings';
import { TransacaoFilters } from '../components/filters/TransacaoFilters';
import { TransacaoSummary } from '../components/summary/TransacaoSummary';
import { TransacaoTable } from '../components/table/TransacaoTable';

export default createComponent({
  name: 'ExtratoPage',
  setup(props, ctx) {
    const {
      $items,
      $itemsLoading,
      $dates,
      $summary,
      $summaryLoading,
      handleFilter,
    } = useTransacoesPage(null);

    function editRouteFn(id: string, item: IExtratoItem) {
      if (item.tipo === TransacaoTipo.DESPESA) {
        return Routes.app.financas.despesas.edit(id);
      }

      return Routes.app.financas.receitas.edit(id);
    }

    return () => (
      <MyPage title="Extrato">
        <TransacaoFilters onInput={handleFilter} />

        <PageSection>
          <FormHeader title="Extrato" noPadding />

          <TransacaoTable
            items={$items.value}
            loading={$itemsLoading.value}
            editRouteFn={editRouteFn}
            tipo={null}
          />
        </PageSection>

        <TransacaoSummary
          dates={$dates.value}
          loading={$summaryLoading.value}
          summary={$summary.value}
        />
      </MyPage>
    );
  },
});

import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { NumberCountUp } from '@/components/utils/NumberCountUp';
import { useState } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { formatMoney } from '@/lib/form';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { FinancasDashboardService } from '@/lib/services/financas/financasDashboard.service';
import { createComponent } from '@/lib/vue';
import { IContaFinanceiraFragment } from '@/typings';
import { computed } from '@vue/composition-api';
import { PropType } from 'vue';

export const ContasFinanceirasCard = createComponent({
  name: 'ContasFinanceirasCard',
  setup(props, ctx) {
    const $state = useState(s => s.financas.dashboard.contasFinanceirasSaldo);

    const $total = computed(() =>
      $state.value.data.reduce((total, conta) => (total += conta.saldo), 0),
    );

    return () => {
      const { data, loading } = $state.value;

      const hasData = data.length > 0;

      return (
        <DashboardCard
          title="Contas financeiras"
          updateAction={FinancasDashboardService.contasFinanceirasSaldo}
          noData={!hasData}
          loading={loading}
        >
          {hasData && (
            <div class="flex flex-col w-full">
              <div class="flex items-center space-x-2">
                <span class="font-normal text-title text-coolGray-500">
                  Saldo geral:
                </span>

                <NumberCountUp
                  class={['text-display', saldoColor($total.value)]}
                  money
                  value={$total.value}
                />
              </div>

              <v-slide-group show-arrows class="my-4">
                {data.map(v => (
                  <ContaCard value={v} key={v.id} />
                ))}
              </v-slide-group>
            </div>
          )}
        </DashboardCard>
      );
    };
  },
});

const ContaCard = createComponent({
  name: 'ContaFinanceiraCard',
  props: {
    value: {
      type: Object as PropType<IContaFinanceiraFragment>,
      required: true,
    },
  },
  setup(props, ctx) {
    return () => {
      const { nome, saldo, banco } = props.value;

      const tipo = mapTipo(props.value);

      return (
        <v-slide-item>
          <div class="flex max-w-md m-2 space-x-4 border border-gray-200 border-solid shadow pa-4 w-80">
            <div
              class="self-center"
              aria-label={tipo.label}
              data-balloon-pos="up"
            >
              <v-icon size="48" color={MyTheme.coolGray500}>
                {tipo.icon}
              </v-icon>
            </div>

            <div class="flex flex-col w-0 space-y-1">
              <div class="text-lg text-title text-coolGray-500">{nome}</div>

              <div class={['text-headline font-medium', saldoColor(saldo)]}>
                {formatMoney(saldo)}
              </div>

              <div class="truncate text-coolGray-400">{mapBanco(banco)}</div>
            </div>
          </div>
        </v-slide-item>
      );
    };
  },
});

function mapBanco(codigo: string | null) {
  return ConstantsHelper.bancos.find(f => f.value === codigo)?.label;
}

function mapTipo(conta: IContaFinanceiraFragment) {
  return (
    ConstantsHelper.contaFinanceiraTipos.find(f => f.value === conta.tipo) ||
    ConstantsHelper.contaFinanceiraTipos[0]
  );
}

function saldoColor(value: number) {
  return {
    'text-red-500': value < 0,
    'text-green-600': value > 0,
    'text-coolGray-600': value === 0,
  };
}

import { PieChart } from '@/components/charts/PieChart';
import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { useState } from '@/lib/composables';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { DashboardService } from '@/lib/services';
import { createComponent } from '@/lib/vue';

export const ReceitasPorProcedimentosCard = createComponent({
  name: 'ReceitasPorProcedimentosCard',
  setup(props, ctx) {
    const $state = useState(s => s.financas.dashboard.receitasPorProcedimentos);

    return () => {
      const { data, loading } = $state.value;

      return (
        <DashboardCard
          title="Receitas por procedimentos"
          updateAction={DashboardService.atendimentosPeriodo}
          noData={data.length === 0}
          loading={loading}
        >
          <PieChart
            type="doughnut"
            value={data}
            colors={ChartHelpers.pie.colors()}
            tooltipFormat={{ money: true }}
          />
        </DashboardCard>
      );
    };
  },
});

import {
  ITransacaoFormModel,
  ITransacaoFragment,
  ITransacaoItem,
  ITransacaoPagoStatus,
  ITransacoesQuery,
  TipoRepeticao,
  TransacaoTipo,
} from '@/typings';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import { DateTime } from 'luxon';
import { DateHelpers } from '../date.helpers';

const getDateFromTipoRepeticao = ({
  vencimento,
  num,
  tipoRepeticao,
}: {
  vencimento: string;
  num: number;
  tipoRepeticao: TipoRepeticao;
}) => {
  const date = DateTime.fromFormat(vencimento, 'dd/MM/yyyy');

  switch (tipoRepeticao) {
    case TipoRepeticao.ANUALMENTE:
      return date.plus({ years: num }).toJSDate();
    case TipoRepeticao.DIARIAMENTE:
      return date.plus({ days: num }).toJSDate();
    case TipoRepeticao.MENSALMENTE:
      return date.plus({ months: num }).toJSDate();
    case TipoRepeticao.QUIZENALMENTE:
      return date.plus({ days: 15 * num }).toJSDate();
    case TipoRepeticao.SEMANALMENTE:
      return date.plus({ weeks: num }).toJSDate();
  }
};

export const calculaVencimento = ({
  vencimento,
  num,
  tipoRepeticao,
}: {
  vencimento: string;
  num: number;
  tipoRepeticao: TipoRepeticao;
}) => {
  if (num === 0) {
    return vencimento;
  }

  const date = getDateFromTipoRepeticao({
    vencimento,
    num,
    tipoRepeticao,
  });

  return DateHelpers.formatDate(date)!;
};

export const isRecorrencia = (model: ITransacaoFormModel | null | undefined) =>
  !!model?.repeticao.geral.recorrencia?.index;

export const getTransacaoPagoStatus = ({
  pago,
  vencimento,
  dataPagamento,
}: ITransacaoFragment): ITransacaoPagoStatus => {
  if (pago || dataPagamento) {
    return 'Pago';
  }

  const diffDays = DateHelpers.dateDiff(
    DateHelpers.today(),
    vencimento,
    'days',
  );
  if (diffDays && diffDays.days < 0) {
    return 'Vencido';
  }

  return 'A Vencer';
};

export const getTransacaoCategoria = ({
  categoria,
  saldoInicial,
  transferencia,
}: ITransacaoFragment) => {
  if (saldoInicial) {
    return 'Saldo inicial';
  } else if (transferencia) {
    return 'Transferência';
  }

  return categoria?.nome;
};

export const getTransacaoParcela = ({
  recorrenciaIndex,
  recorrencia,
}: {
  recorrenciaIndex: number | null;
  recorrencia: { numero: number | null } | null;
}) => {
  if (!isNumber(recorrenciaIndex) || !recorrencia) {
    return null;
  }

  const num = recorrenciaIndex === 0 ? 'Entrada' : recorrenciaIndex;

  return `${num}/${recorrencia.numero}`;
};

export const getTransacoesItems = (
  result: ITransacoesQuery | null,
): ITransacaoItem[] => result?.transacoes.nodes.map(mapTransacaoItem) || [];

export const mapTransacaoItem = (v: ITransacaoFragment): ITransacaoItem => ({
  id: v.id,
  data: v.dataExtrato,
  descricao: v.descricao,
  tipo: v.tipo,
  categoria: getTransacaoCategoria(v),
  pago: v.pago,
  pagoStatus: getTransacaoPagoStatus(v),
  valor: v.valor,
  parcela: getTransacaoParcela(v),
  saldoInicial: v.saldoInicial,
  transferencia: v.transferencia,
  procedimento: v.procedimento?.nome,
  convenio: v.convenio?.nome,
  noActions: v.saldoInicial || v.transferencia,
});

export const getTransacaoSaldo = ({
  idx,
  items,
}: {
  idx: number;
  items: ITransacaoItem[];
}): number =>
  items
    .slice(0, idx + 1)
    .reduce(
      (saldoAnterior, v) =>
        round(
          v.tipo === TransacaoTipo.DESPESA
            ? saldoAnterior - v.valor
            : saldoAnterior + v.valor,
          2,
        ),
      0,
    );

import { ExpandTransition } from '@/components/utils/ExpandTransition';
import { formatMoney } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { calculaVencimento } from '@/lib/helpers/models/transacao';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import {
  ITransacaoRepeticaoModel,
  ITransacaoRepeticaoPreviewItem,
  TipoRepeticao,
  TransacaoRepeticaoModo,
} from '@/typings';
import { computed, ComputedRef } from '@vue/composition-api';
import times from 'lodash/times';

interface IProps {
  repeticao: ITransacaoRepeticaoModel;
  valor?: number | null;
  vencimento?: string | null;
}

export const TransacaoRepeticaoPreview = createComponent<IProps>({
  name: 'TransacaoRepeticaoPreview',
  props: {
    repeticao: { type: Object, required: true },
    valor: { type: Number },
    vencimento: { type: String },
  },
  setup(props, ctx) {
    const { $hide, $title, $items, $isParcelas, $isRepeticoes } =
      useComputeds(props);

    const { formatLabel } = useEvents({
      props,
      $isRepeticoes,
      $isParcelas,
    });

    return () => (
      <ExpandTransition hide={$hide.value}>
        <v-subheader>{$title.value}</v-subheader>

        <v-divider />

        <v-list dense>
          <transition-group name="slide-y-transition">
            {$items.value.map(item => listTile({ item, formatLabel }))}
          </transition-group>
        </v-list>
      </ExpandTransition>
    );
  },
});

function useComputeds(props: IProps) {
  const $hide = computed(() => {
    // hide when repeticoes and don't have any numeroRepeticoes or tipoRepeticao
    const hideRepeticoes =
      $isRepeticoes.value &&
      (!props.repeticao.repeticoes.numeroRepeticoes ||
        !props.repeticao.repeticoes.tipoRepeticao);

    // hide when parcelas and don't have any numeroParcelas
    const hideParcelas =
      $isParcelas.value && !props.repeticao.parcelas.numeroParcelas;

    return (
      !props.repeticao.geral.repetir ||
      !props.repeticao.geral.modo ||
      hideRepeticoes ||
      hideParcelas
    );
  });

  const $isParcelas = computed(
    () => props.repeticao.geral.modo === TransacaoRepeticaoModo.PARCELAS,
  );

  const $isRepeticoes = computed(
    () => props.repeticao.geral.modo === TransacaoRepeticaoModo.REPETICAO,
  );

  const $title = computed(() => {
    const tipo = $isParcelas.value ? 'Parcelas' : 'Repetições';

    return `Simulação das ${tipo}`;
  });

  const $items = computed(() => {
    const items: ITransacaoRepeticaoPreviewItem[] = [];

    const vencimentoInicial = DateHelpers.formatDate(
      props.vencimento || new Date(),
    )!;

    if ($isParcelas.value) {
      if (props.repeticao.parcelas.valorEntrada) {
        items.push({
          index: 0,
          valor: props.repeticao.parcelas.valorEntrada,
          vencimento: vencimentoInicial,
        });
      }

      let numParcelas = props.repeticao.parcelas.numeroParcelas || 0;

      // make sure to only show 3 parcelas
      numParcelas = numParcelas > 3 ? 3 : numParcelas;

      times(numParcelas, idx => {
        const num = idx + 1;

        items.push({
          index: num,
          valor: props.repeticao.parcelas.valorParcela!,
          vencimento: calculaVencimento({
            vencimento: vencimentoInicial,
            tipoRepeticao: TipoRepeticao.MENSALMENTE,
            num,
          }),
        });
      });
    } else if ($isRepeticoes.value) {
      let numRepeticoes = props.repeticao.repeticoes.numeroRepeticoes || 0;

      numRepeticoes = numRepeticoes > 3 ? 3 : numRepeticoes!;

      times(numRepeticoes, idx => {
        items.push({
          index: idx + 1,
          valor: props.valor || 0,
          vencimento: calculaVencimento({
            vencimento: vencimentoInicial,
            tipoRepeticao: props.repeticao.repeticoes.tipoRepeticao!,
            num: idx,
          }),
        });
      });
    }

    return items;
  });

  return {
    $hide,
    $isParcelas,
    $isRepeticoes,
    $title,
    $items,
  };
}

function useEvents({
  props,
  $isRepeticoes,
  $isParcelas,
}: {
  props: IProps;
  $isRepeticoes: ComputedRef<boolean>;
  $isParcelas: ComputedRef<boolean>;
}) {
  function formatLabel(item: ITransacaoRepeticaoPreviewItem) {
    const max = $isRepeticoes.value
      ? props.repeticao.repeticoes.numeroRepeticoes!
      : props.repeticao.parcelas.numeroParcelas!;

    const tipo = $isParcelas.value ? 'Parcela' : 'Repetição';

    return item.index === 0 ? 'Entrada: ' : `${tipo} ${item.index} de ${max}: `;
  }

  return { formatLabel };
}

const listTile = ({
  item,
  formatLabel,
}: {
  item: ITransacaoRepeticaoPreviewItem;
  formatLabel: (item: ITransacaoRepeticaoPreviewItem) => string;
}) => (
  <v-list-item key={item.index}>
    <v-list-item-action>
      <v-icon>{getIcon(item)}</v-icon>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        <span>{formatLabel(item)}</span>

        <strong class="ml-2">
          {formatMoney(item.valor)} - {item.vencimento}
        </strong>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
);

function getIcon(item: ITransacaoRepeticaoPreviewItem) {
  switch (item.index) {
    case 1:
      return MyIcons.number1Circle;

    case 2:
      return MyIcons.number2Circle;

    case 3:
      return MyIcons.number3Circle;

    case 0:
    default:
      return MyIcons.circle;
  }
}

import { FormFields } from '@/components/form/fields/FormFields';
import { ConstantsHelper } from '@/lib/constants/helper';
import { DateRangePresets } from '@/lib/constants/dateRangePresets';
import { LookupsConfigs } from '@/lib/form/lookups';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IFormInputLayout,
  IFormSchema,
  IProcedimentosQuery,
  ITransacaoFilter,
  ITransacaoFilterModel,
  TransacaoTipo,
  TransacaoWhereInput,
} from '@/typings';
import { computed, Ref, ref, SetupContext } from '@vue/composition-api';
import groupBy from 'lodash/groupBy';
import isBoolean from 'lodash/isBoolean';

interface IProps {
  filters: ITransacaoFilter[];
  contaFinanceiraId?: string | null;
  tipo?: TransacaoTipo | null;
}

interface IEvents {
  onInput: (where: TransacaoWhereInput) => void;
}

export const TransacaoFiltersFields = createComponent<IProps, IEvents>({
  name: 'TransacaoFiltersFields',
  props: {
    filters: { type: Array, required: true },
    contaFinanceiraId: { type: String },
    tipo: { type: String as any },
  },
  setup(props, ctx) {
    const $filtersModel = useFiltersModel();

    const $schema = useSchema(props);

    useEvents({ props, ctx, $filtersModel });

    return () => (
      <FormFields schema={$schema.value} v-model={$filtersModel.value} full />
    );
  },
});

function useFiltersModel() {
  return ref<ITransacaoFilterModel>({
    data: { ...DateRangePresets.esteMes },
    descricao: null,
    categoriaId: null,
    centroCustoId: null,
    contaFinanceiraId: null,
    convenioId: null,
    formaPagamento: null,
    pacienteId: null,
    procedimentosIds: null,
    profissionalId: null,
    status: null,
    tipo: null,
  }) as Ref<ITransacaoFilterModel>;
}

function useSchema(props: IProps) {
  function hasFilter(filter: ITransacaoFilter) {
    return props.filters.includes(filter);
  }

  const layout = (opts: IFormInputLayout = {}): IFormInputLayout => ({
    sm: 4,
    marginBottom: 4,
    ...opts,
  });

  return computed<IFormSchema<ITransacaoFilterModel>>(() => ({
    data: {
      label: 'Data',
      type: 'date-range',
      hidden: !hasFilter('Data'),
      hideDetails: true,
      layout: layout({ minWidth: 270 }),
    },
    descricao: {
      label: 'Descrição',
      type: 'text',
      hidden: !hasFilter('Descrição'),
      hideDetails: true,
      layout: layout(),
    },
    categoriaId: {
      label: 'Categoria',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.categoriaFinanceira(),
      hidden: !hasFilter('Categoria'),
      hideDetails: true,
      layout: layout(),
    },
    centroCustoId: {
      label: 'Centro de custo',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.centroCusto(),
      hidden: !hasFilter('Centro de custo'),
      hideDetails: true,
      layout: layout(),
    },
    contaFinanceiraId: {
      label: 'Conta',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.contaFinanceira(),
      hidden: !hasFilter('Conta'),
      hideDetails: true,
      layout: layout(),
    },
    convenioId: {
      label: 'Convênio',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.convenio(),
      hidden: !hasFilter('Convênio'),
      hideDetails: true,
      layout: layout(),
    },
    formaPagamento: {
      label: 'Forma de pagamento',
      type: 'select',
      items: ConstantsHelper.formasPagamento,
      clearable: true,
      hidden: !hasFilter('Forma de pagamento'),
      hideDetails: true,
      layout: layout({ minWidth: 230 }),
    },
    pacienteId: {
      label: 'Paciente',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.paciente(),
      hidden: !hasFilter('Paciente'),
      hideDetails: true,
      layout: layout(),
    },
    procedimentosIds: {
      label: 'Procedimento',
      type: 'autocomplete',
      itemLabel: 'nome',
      itemValue: 'ids',
      lookup: LookupsConfigs.procedimento({
        mapData: (data: IProcedimentosQuery) => {
          const procedimentos = data.procedimentos.nodes.map(v => ({
            id: v.id,
            nome: v.nome,
          }));

          if (!procedimentos.length) {
            return [];
          }

          return Object.entries(groupBy(procedimentos, 'nome')).map(
            ([key, value]) => ({
              ids: value.map(v => v.id),
              nome: key,
            }),
          );
        },
        noCache: true,
      }),
      hidden: !hasFilter('Procedimento'),
      hideDetails: true,
      layout: layout(),
    },
    profissionalId: {
      label: 'Profissional',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.user.profissionais(),
      hidden: !hasFilter('Profissional'),
      hideDetails: true,
      layout: layout(),
    },
    status: {
      label: 'Status',
      type: 'select',
      items: ConstantsHelper.transacaoStatus,
      clearable: true,
      hidden: !hasFilter('Status'),
      hideDetails: true,
      layout: layout({ minWidth: 180, sm: 2 }),
    },
    tipo: {
      label: 'Tipo',
      type: 'select',
      items: ConstantsHelper.transacaoTipos,
      clearable: true,
      hidden: !hasFilter('Tipo'),
      hideDetails: true,
      layout: layout({ minWidth: 180, sm: 2 }),
    },
  }));
}

function useEvents({
  props,
  ctx,
  $filtersModel,
}: {
  props: IProps;
  ctx: SetupContext;
  $filtersModel: ReturnType<typeof useFiltersModel>;
}) {
  function onFiltersModelChange(model: ITransacaoFilterModel) {
    const where: TransacaoWhereInput = {};

    if (model.data) {
      where.dataExtrato_gte = model.data.startDate;
      where.dataExtrato_lte = model.data.endDate;
    }

    if (model.descricao) {
      where.descricao_contains = model.descricao;
    }

    if (model.categoriaId) {
      where.categoriaId = model.categoriaId;
    }

    if (model.centroCustoId) {
      where.centroCustoId = model.centroCustoId;
    }

    if (model.contaFinanceiraId) {
      where.contaFinanceiraId = model.contaFinanceiraId;
    }

    if (model.convenioId) {
      where.convenioId = model.convenioId;
    }

    if (model.formaPagamento) {
      where.formaPagamento = model.formaPagamento;
    }

    if (model.pacienteId) {
      where.pacienteId = model.pacienteId;
    }

    if (model.procedimentosIds) {
      where.procedimentoId_in = model.procedimentosIds;
    }

    if (model.profissionalId) {
      where.profissionalId = model.profissionalId;
    }

    if (isBoolean(model.status)) {
      where.pago = model.status;
    }

    if (model.tipo) {
      where.tipo = model.tipo;
    }

    return emitInput(where);
  }

  function emitInput(where: TransacaoWhereInput) {
    ctx.emit('input', where);
  }

  function handleDefaultChange() {
    if (props.tipo) {
      $filtersModel.value.tipo = props.tipo;
    }

    if (props.contaFinanceiraId) {
      $filtersModel.value.contaFinanceiraId = props.contaFinanceiraId;
    }
  }

  watchRun($filtersModel, onFiltersModelChange, { deep: true });
  watchRun(() => props.tipo, handleDefaultChange);
  watchRun(() => props.contaFinanceiraId, handleDefaultChange);
}

import { CircularLoading } from '@/components/loading/CircularLoading';
import { PagePanel } from '@/components/page/PagePanel';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent } from '@/lib/vue';
import {
  ITransacaoSummaryDates,
  ITransacoesSummaryQuery_transacoesSummary,
  ITransacoesSummaryQuery_transacoesSummary_despesas,
  ITransacoesSummaryQuery_transacoesSummary_receitas,
  TransacaoTipo,
} from '@/typings';
import { computed } from '@vue/composition-api';
import { TransacaoSummaryRow } from './TransacaoSummaryRow';

interface IProps {
  dates?: ITransacaoSummaryDates | null;
  summary?: ITransacoesSummaryQuery_transacoesSummary | null;
  loading?: boolean;
  tipo?: TransacaoTipo | null;
}

export const TransacaoSummary = createComponent<IProps>({
  name: 'TransacaoSummary',
  props: {
    dates: { type: Object },
    summary: { type: Object },
    loading: { type: Boolean, default: false },
    tipo: { type: String as any },
  },
  setup(props, ctx) {
    const {
      $periodo,
      $despesas,
      $receitas,
      $total,
      $saldoColor,
      $isDespesa,
      $isReceita,
    } = useComputeds(props);

    return () => (
      <PagePanel title="Totalização" class="justify-center">
        <CircularLoading loading={!!props.loading}>
          {props.summary && (
            <div class="flex flex-col">
              <h2 class="mb-4 text-center text-title">{$periodo.value}</h2>

              <div class="flex justify-center m-4">
                <div class="flex flex-col max-w-xl">
                  {props.tipo && <v-divider class="my-2" />}

                  <Despesas
                    isReceita={$isReceita.value}
                    despesas={$despesas.value}
                  />

                  <Receitas
                    isDespesa={$isDespesa.value}
                    receitas={$receitas.value}
                  />

                  <Totais
                    tipo={props.tipo}
                    despesas={$despesas.value}
                    receitas={$receitas.value}
                  />

                  <v-divider class="my-2" />

                  <TransacaoSummaryRow
                    title="Total do período"
                    total={$total.value}
                    sumClass={$saldoColor.value}
                    class="font-bold text-coolGray-800"
                  />
                </div>
              </div>
            </div>
          )}
        </CircularLoading>
      </PagePanel>
    );
  },
});

function useComputeds(props: IProps) {
  const $periodo = computed(() => {
    if (!props.dates) return null;

    const startDate = DateHelpers.formatDate(props.dates.startDate);
    const endDate = DateHelpers.formatDate(props.dates.endDate);

    return `Período de ${startDate} à ${endDate}`;
  });

  const $despesas = computed(() =>
    props.summary ? props.summary.despesas : null,
  );

  const $receitas = computed(() =>
    props.summary ? props.summary.receitas : null,
  );

  const $total = computed(() => (props.summary ? props.summary.overall : null));

  const $saldoColor = computed(() => {
    if (!props.summary) return null;

    return props.summary.overall.sum >= 0 ? 'text-blue-700' : 'text-red-600';
  });

  const $isDespesa = computed(() => props.tipo === TransacaoTipo.DESPESA);

  const $isReceita = computed(() => props.tipo === TransacaoTipo.RECEITA);

  return {
    $periodo,
    $despesas,
    $receitas,
    $total,
    $saldoColor,
    $isDespesa,
    $isReceita,
  };
}

const Despesas = createComponent<{
  isReceita: boolean;
  despesas: ITransacoesSummaryQuery_transacoesSummary_despesas | null;
}>({
  name: 'TransacaoSummaryDespesas',
  props: {
    isReceita: { type: Boolean, required: true },
    despesas: { type: Object },
  },
  setup(props, ctx) {
    return () => {
      if (props.isReceita || !props.despesas) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <TransacaoSummaryRow
            title="Despesas pendentes"
            total={props.despesas.notPaid}
            class="text-red-500"
          />

          <TransacaoSummaryRow
            title="Despesas pagas"
            total={props.despesas.paid}
            class="text-red-500"
          />
        </div>
      );
    };
  },
});

const Receitas = createComponent<{
  isDespesa: boolean;
  receitas: ITransacoesSummaryQuery_transacoesSummary_receitas | null;
}>({
  name: 'TransacaoSummaryReceitas',
  props: {
    isDespesa: { type: Boolean, required: true },
    receitas: { type: Object },
  },
  setup(props, ctx) {
    return () => {
      if (props.isDespesa || !props.receitas) {
        return null;
      }

      return (
        <div>
          <TransacaoSummaryRow
            title="Receitas pendentes"
            total={props.receitas.notPaid}
            class="text-success"
          />

          <TransacaoSummaryRow
            title="Receitas recebidas"
            total={props.receitas.paid}
            class="text-success"
          />
        </div>
      );
    };
  },
});

interface ITotaisProps {
  tipo?: TransacaoTipo | null | undefined;
  despesas: ITransacoesSummaryQuery_transacoesSummary_despesas | null;
  receitas: ITransacoesSummaryQuery_transacoesSummary_receitas | null;
}

const Totais = createComponent<ITotaisProps>({
  name: 'TransacaoSummaryTotais',
  props: {
    tipo: { type: String as any },
    despesas: { type: Object },
    receitas: { type: Object },
  },
  setup(props, ctx) {
    return () => {
      if (props.tipo) {
        return null;
      }

      return (
        <div>
          <v-divider class="my-2" />

          {props.despesas && (
            <TransacaoSummaryRow
              title="Total de despesas"
              total={props.despesas.total}
              class="font-medium text-red-500"
            />
          )}

          {props.receitas && (
            <TransacaoSummaryRow
              title="Total de receitas"
              total={props.receitas.total}
              class="font-medium text-success"
            />
          )}
        </div>
      );
    };
  },
});

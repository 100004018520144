import { Td } from '@/components/datatable/columns/Td';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import {
  IExtratoItem,
  ITransacaoItem,
  IDataTableHeader,
  TransacaoTipo,
} from '@/typings';
import { computed, Ref } from '@vue/composition-api';
import capitalize from 'lodash/capitalize';

interface IProps {
  header: IDataTableHeader;
  item: IExtratoItem | ITransacaoItem;
  noCircle?: boolean;
  singleLine?: boolean;
}

export const TransacaoDescricaoTd = createComponent<IProps>({
  name: 'TransacaoDescricaoTd',
  props: {
    header: { type: Object, required: true },
    item: { type: Object, required: true },
    noCircle: { type: Boolean, default: true },
    singleLine: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const { $tipo, $color, $topClasses } = useComputeds(props);

    return () => {
      const { parcela, convenio, procedimento } = props.item;

      return (
        <Td header={props.header} item={props.item}>
          <div class="flex flex-col">
            <div class={['flex items-center', $topClasses.value]}>
              {circle({ props, $tipo, $color })}

              <div class="flex flex-row items-center flex-1 space-x-2">
                <div>{props.item.descricao}</div>

                {parcelaInfo(parcela)}
              </div>
            </div>

            {(convenio || procedimento) && !props.singleLine && (
              <div class="flex space-x-4">
                {procedimentoInfo(procedimento)}

                {convenioInfo(convenio)}
              </div>
            )}
          </div>
        </Td>
      );
    };
  },
});

function useComputeds(props: IProps) {
  const $tipo = computed(() => capitalize(props.item.tipo));

  const $color = computed(() => {
    switch (props.item.tipo) {
      case TransacaoTipo.RECEITA:
        return MyTheme.green600;

      case TransacaoTipo.DESPESA:
        return MyTheme.red500;

      default:
        return null;
    }
  });

  const $topClasses = computed(() => ({
    'pt-2': !!props.item.procedimento || !!props.item.convenio,
  }));

  return { $tipo, $color, $topClasses };
}

const circle = ({
  props,
  $tipo,
  $color,
}: {
  props: IProps;
  $tipo: Ref<string>;
  $color: Ref<string | null>;
}) =>
  !props.noCircle && (
    <div aria-label={$tipo.value} data-balloon-pos="up">
      <v-icon size="20" color={$color.value} class="pr-2">
        {MyIcons.circle}
      </v-icon>
    </div>
  );

const parcelaInfo = (parcela: string | null | undefined) =>
  parcela && <span class="text-gray-500">{parcela}</span>;

const procedimentoInfo = (procedimento: string | null | undefined) =>
  procedimento && (
    <div
      aria-label="Procedimento"
      data-balloon-pos="down"
      class="flex-row items-center py-2 space-x-1"
    >
      <v-icon size="18" color={MyTheme.gray500}>
        {MyIcons.procedimento}
      </v-icon>

      <span class="text-gray-500">{procedimento}</span>
    </div>
  );

const convenioInfo = (convenio: string | null | undefined) =>
  convenio && (
    <div
      aria-label="Convênio"
      data-balloon-pos="down"
      class="flex-row items-center py-2 space-x-1"
    >
      <v-icon size="18" color={MyTheme.gray500}>
        {MyIcons.convenio}
      </v-icon>

      <span class="text-gray-500">{convenio}</span>
    </div>
  );

import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useTransacaoIndexRoute } from '@/lib/composables/transacao/useTransacao';
import { TransacaoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ITransferenciaModel } from '@/typings';
import { TransferenciaForm } from '../components/forms/TransferenciaForm';

export default createComponent({
  name: 'TransferenciaPage',
  setup(props, ctx) {
    const { $indexRoute } = useTransacaoIndexRoute(ctx);

    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback(model: ITransferenciaModel) {
        return TransacaoService.createTransferencia({
          model,
          indexRoute: $indexRoute.value,
        });
      },
    });

    return () => (
      <MyPage title="Transferência">
        <TransferenciaForm
          page={page}
          indexRoute={$indexRoute.value}
          onSubmit={handleSubmit}
        />
      </MyPage>
    );
  },
});

import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { SERVER_MAX_TAKE } from '@/lib/constants/server';
import { LookupsConfigs } from '@/lib/form/lookups';
import { createComponent } from '@/lib/vue';
import {
  IContasFinanceirasQueryVariables,
  IFormSchema,
  ITransferenciaModel,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {
  indexRoute: string;
}

interface IEvents extends IFormEvents<ITransferenciaModel> {}

export const TransferenciaForm = createComponent<IProps, IEvents>({
  name: 'TransferenciaForm',
  props: {
    page: { type: Object, required: true },
    indexRoute: { type: String, required: true },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={props.indexRoute}
        onSubmit={emitSubmit}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<ITransferenciaModel, IFormSchema<ITransferenciaModel>>({
    page: props.page,
    ctx,
    initialValue: {
      contaFinanceiraOrigemId: null,
      contaFinanceiraDestinoId: null,
      descricao: null,
      valor: null,
      data: null,
    },
    mapSchema: model => ({
      descricao: {
        label: 'Descrição',
        type: 'text',
        validations: {
          required: true,
        },
      },
      contaFinanceiraOrigemId: {
        label: 'Conta de Origem',
        type: 'autocomplete',
        itemLabel: 'nome',
        lookup: LookupsConfigs.contaFinanceira({
          getVariables: (searchValue): IContasFinanceirasQueryVariables => ({
            where: {
              nome_contains: searchValue,
              id_not: model.contaFinanceiraDestinoId || undefined,
            },
            take: SERVER_MAX_TAKE,
          }),
          noCache: true,
        }),
        validations: {
          required: true,
        },
        layout: {
          sm: 6,
        },
      },
      contaFinanceiraDestinoId: {
        label: 'Conta de Destino',
        type: 'autocomplete',
        itemLabel: 'nome',
        lookup: LookupsConfigs.contaFinanceira({
          getVariables: (searchValue): IContasFinanceirasQueryVariables => ({
            where: {
              nome_contains: searchValue,
              id_not: model.contaFinanceiraOrigemId || undefined,
            },
            take: SERVER_MAX_TAKE,
          }),
          noCache: true,
        }),
        validations: { required: true },
        layout: { sm: 6 },
      },
      valor: {
        label: 'Valor',
        type: 'money',
        validations: { required: true },
        layout: { maxWidth: 260 },
      },
      data: {
        label: 'Data',
        type: 'date',
        validations: { required: true },
        layout: { maxWidth: 254 },
      },
    }),
  });
}

import { formatMoney } from '@/lib/form';
import { createComponent } from '@/lib/vue';

interface ITransacoesSummaryValues {
  count: number;
  sum: number;
}

interface IProps {
  title: string;
  total: ITransacoesSummaryValues | null;
  sumClass?: string | null | undefined;
}

export const TransacaoSummaryRow = createComponent<IProps>({
  name: 'TransacaoSummaryRow',
  props: {
    title: { type: String, required: true },
    total: { type: Object, required: true },
    sumClass: { type: String },
  },
  setup(props, ctx) {
    return () => {
      const count = props.total?.count || 0;
      const sum = props.total?.sum || 0;

      return (
        <div class="flex items-center justify-between py-1 text-subtitle">
          <span>
            {props.title}: {count}
          </span>

          <span class={[props.sumClass]}>{formatMoney(sum)}</span>
        </div>
      );
    };
  },
});

import { Routes } from '@/routes/routes';
import { ITransacaoFormModel, TransacaoTipo } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { DialogHelpers } from '../../helpers/dialogs/dialog.helpers';
import { isRecorrencia } from '../../helpers/models/transacao';
import { MyTheme } from '../../helpers/MyTheme';
import { TransacaoService } from '../../services';
import { useFormPage } from '../form/useFormPage';
import { useRoute } from '../utils/useRouter';

export function useTransacaoIndexRoute(ctx: SetupContext) {
  const indexIsReceitas = useRoute().query.idx === 'r';
  const indexIsDespesas = useRoute().query.idx === 'd';

  const $indexRoute = computed(() => {
    if (indexIsReceitas) {
      return Routes.app.financas.receitas.index;
    } else if (indexIsDespesas) {
      return Routes.app.financas.despesas.index;
    }

    return Routes.app.financas.extrato;
  });

  return {
    indexIsReceitas,
    indexIsDespesas,
    $indexRoute,
  };
}

export function useTransacaoFormPage({
  ctx,
  tipo,
}: {
  ctx: SetupContext;
  tipo: TransacaoTipo;
}) {
  const { handleSubmit, isEdit, page } = useFormPage<ITransacaoFormModel>({
    ctx,
    async submitCallback(model) {
      if (page.id) {
        if (isRecorrencia(model)) {
          return openOptionsDialog('save', model);
        }

        return salvarTransacao(model);
      } else {
        return TransacaoService.create({
          model,
          indexRoute: $indexRoute.value,
          tipo,
        });
      }
    },
  });

  const { $indexRoute } = useTransacaoIndexRoute(ctx);

  async function openOptionsDialog(
    dialogType: 'save' | 'delete',
    model: ITransacaoFormModel,
  ) {
    const transacaoTipo = tipo.toLowerCase();

    if (dialogType === 'delete') {
      return DialogHelpers.system.options(`Excluir ${transacaoTipo}`, {
        text: `Esta ${transacaoTipo} se repete em outras datas, deseja excluir permanentemente?`,
        options: [
          {
            text: 'excluir apenas esta',
            handleClick: deleteTransacao,
            btnColor: MyTheme.red500,
          },
          {
            text: 'excluir esta e as próximas',
            handleClick: () => deleteRecorrencia(model),
            btnColor: MyTheme.red500,
          },
          {
            text: 'excluir todas',
            handleClick: () => deleteRecorrenciaAll(model),
            btnColor: MyTheme.red500,
          },
          {
            text: 'não excluir',
          },
        ],
      });
    }

    // dialogType === 'save'
    return DialogHelpers.system.options(`Salvar ${transacaoTipo}`, {
      text: `Esta ${transacaoTipo} se repete em outras datas`,
      options: [
        {
          text: 'salvar apenas esta',
          handleClick: () => salvarTransacao(model),
        },
        {
          text: 'salvar esta e as próximas',
          handleClick: () => salvarRecorrencia(model),
        },
        {
          text: 'não salvar',
        },
      ],
    });
  }

  async function handleDelete(id: string, model?: ITransacaoFormModel) {
    if (model && isRecorrencia(model)) {
      return openOptionsDialog('delete', model);
    }

    return TransacaoService.delete({
      id,
      indexRoute: $indexRoute.value,
      tipo,
    });
  }

  async function salvarTransacao(model: ITransacaoFormModel) {
    page.submitting = true;

    await TransacaoService.update({
      id: page.id!,
      model,
      indexRoute: $indexRoute.value,
      tipo,
      updateRecorrencia: false,
    });

    page.submitting = false;
  }

  async function salvarRecorrencia(model: ITransacaoFormModel) {
    page.submitting = true;

    await TransacaoService.update({
      id: page.id!,
      model,
      indexRoute: $indexRoute.value,
      tipo,
      updateRecorrencia: true,
    });

    page.submitting = false;
  }

  async function deleteTransacao() {
    return TransacaoService.deleteTransacao({
      id: page.id!,
      indexRoute: $indexRoute.value,
      tipo,
    });
  }

  const getRecorrencia = (model: ITransacaoFormModel) =>
    model.repeticao.geral.recorrencia;

  async function deleteRecorrencia(model: ITransacaoFormModel) {
    const { id: recorrenciaId, index } = getRecorrencia(model)!;

    return TransacaoService.deleteRecorrencia({
      recorrenciaId,
      index,
      all: false,
      indexRoute: $indexRoute.value,
    });
  }

  async function deleteRecorrenciaAll(model: ITransacaoFormModel) {
    const { id: recorrenciaId } = getRecorrencia(model)!;

    return TransacaoService.deleteRecorrencia({
      recorrenciaId,
      all: true,
      indexRoute: $indexRoute.value,
    });
  }

  return {
    page,
    isEdit,
    handleSubmit,
    handleDelete,
    $indexRoute,
  };
}

import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { ConfiguracaoGraphql } from '@/graphql/configuracao/ConfiguracaoGraphql';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { getTransacaoParcela } from '@/lib/helpers/models/transacao';
import { TransacaoService } from '@/lib/services';
import { createComponent, watchRun } from '@/lib/vue';
import { AgendamentoProcedimentosTable } from '@/modules/agenda/components/dialogs/table/AgendamentoProcedimentosTable';
import { redirectError } from '@/routes/utils';
import {
  AgendamentoViewProcedimentoTableMode,
  IAgendamentoRecebimentoProcedimentoModel,
  IForm,
  IFormValidationErrors,
  IRecorrenciaModel,
  ITransacaoFormModel,
  ITransacaoFormSchema,
  ITransacaoFragment,
  ITransacaoFragment_agendamento,
  TransacaoTipo,
} from '@/typings';
import { computed, ComputedRef, Ref, SetupContext } from '@vue/composition-api';
import isNumber from 'lodash/isNumber';
import { TransacaoRepeticaoFields } from './TransacaoRepeticaoFields';

interface IProps extends IFormProps {
  indexRoute: string;
  tipo: TransacaoTipo;
}

interface IEvents extends IFormEvents<ITransacaoFormModel>, INomeEvents {}

export const TransacaoForm = createComponent<IProps, IEvents>({
  name: 'TransacaoForm',
  props: {
    page: { type: Object, required: true },
    indexRoute: { type: String, required: true },
    tipo: { type: String, required: true } as any,
  },
  setup(props, ctx) {
    const { $agendamento, geralTitle, setTransacao } = useData(props);

    const {
      $form,
      $schema,
      isEdit,
      emitSubmit,
      emitDelete,
      setRepeticaoErrors,
    } = useForm({
      props,
      ctx,
      $agendamento,
      setTransacao,
    });

    useNome({
      ctx,
      watchNome: () => $form.value.model.geral.descricao,
    });

    const {
      handleProcedimentosChange,
      handleDescontoChange,
      handleValorTotalChange,
    } = useActions({ $form, isEdit });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={props.indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <GeralFields
          form={$form.value}
          schema={$schema.value}
          geralTitle={geralTitle}
        />

        <ProcedimentosTable
          agendamento={$agendamento.value}
          recorrencia={$form.value.model.repeticao.geral.recorrencia}
          handleProcedimentosChange={handleProcedimentosChange}
          handleDescontoChange={handleDescontoChange}
          handleValorTotalChange={handleValorTotalChange}
        />

        <DadosAdicionaisFields form={$form.value} schema={$schema.value} />

        <TransacaoRepeticaoFields
          isEdit={isEdit}
          form={$form.value}
          valor={$form.value.model.geral.valor}
          vencimento={$form.value.model.geral.vencimento}
          v-model={$form.value.model.repeticao}
          onValidate={setRepeticaoErrors}
        />
      </MyForm>
    );
  },
});

function useData(props: IProps) {
  const [$transacao, setTransacao] = useValue<
    ITransacaoFragment | null | undefined
  >(null);

  const $agendamento = computed(() => $transacao.value?.agendamento);

  const isReceita = props.tipo === TransacaoTipo.RECEITA;

  const geralTitle = isReceita ? 'Receita' : 'Despesa';

  return {
    setTransacao,
    $agendamento,
    geralTitle,
  };
}

function useForm({
  props,
  ctx,
  $agendamento,
  setTransacao,
}: {
  props: IProps;
  ctx: SetupContext;
  $agendamento: ComputedRef<ITransacaoFragment_agendamento | null | undefined>;
  setTransacao: (v: ITransacaoFragment | null | undefined) => void;
}) {
  const [$repeticaoErrors, setRepeticaoErrors] = useValue<
    IFormValidationErrors[]
  >([]);

  const formConfig = useFormConfig<ITransacaoFormModel, ITransacaoFormSchema>({
    page: props.page,
    ctx,
    initialValue: {
      geral: {
        descricao: null,
        valor: null,
        contaFinanceiraId: null,
        categoriaId: null,
        vencimento: null,
        pago: false,
        dataPagamento: null,
        formaPagamento: null,
        centroCustoId: null,
      },
      dadosAdicionais: {
        profissionalId: null,
        procedimentoId: null,
        pacienteId: null,
        convenioId: null,
      },
      repeticao: {
        geral: {
          repetir: false,
          modo: null,
          recorrencia: null,
        },
        repeticoes: {
          tipoRepeticao: null,
          numeroRepeticoes: null,
        },
        parcelas: {
          numeroParcelas: null,
          valorEntrada: null,
          valorParcela: null,
        },
      },
      agendamento: null,
    },
    mapSchema: (model, $form) => {
      const recorrencia = model.repeticao.geral.recorrencia;

      const $descricaoSuffix = computed(() => {
        const isEdit = !!$form.value.page.id;
        if (!isEdit || !recorrencia || !isNumber(recorrencia.index)) {
          return undefined;
        }

        return (
          getTransacaoParcela({
            recorrenciaIndex: recorrencia.index,
            recorrencia,
          }) || undefined
        );
      });

      return {
        geral: {
          descricao: {
            label: 'Descrição',
            type: 'text',
            suffix: $descricaoSuffix.value,
            validations: { required: true },
          },
          valor: {
            label: 'Valor',
            type: 'money',
            disabled: !recorrencia && !!$agendamento.value,
            validations: { required: true },
            layout: { maxWidth: 240 },
          },
          vencimento: {
            label: 'Vencimento',
            type: 'date',
            validations: { required: true },
            layout: { maxWidth: 236 },
          },
          contaFinanceiraId: {
            label: 'Conta financeira',
            type: 'autocomplete',
            itemLabel: 'nome',
            lookup: LookupsConfigs.contaFinanceira(),
            validations: { required: true },
            layout: { sm: 6 },
          },
          categoriaId: {
            label: 'Categoria',
            type: 'autocomplete',
            itemLabel: 'nome',
            lookup: LookupsConfigs.categoriaFinanceira({
              tipo: props.tipo,
            }),
            validations: { required: true },
            layout: { sm: 6 },
          },
          centroCustoId: {
            label: 'Centro de custo',
            type: 'autocomplete',
            itemLabel: 'nome',
            lookup: LookupsConfigs.centroCusto(),
            layout: { sm: 6 },
          },
          formaPagamento: {
            label: 'Forma de pagamento',
            type: 'select',
            items: ConstantsHelper.formasPagamento,
            validations: { required: true },
            layout: { sm: 6 },
          },
          pago: {
            label: 'Pago',
            type: 'checkbox',
            layout: { maxWidth: 100 },
          },
          dataPagamento: {
            label: 'Data do pagamento',
            type: 'date',
            disabled: !model.geral.pago,
            validations: {
              required: model.geral.pago,
              maxDate: DateHelpers.today(),
            },
            layout: { maxWidth: 260 },
          },
        },
        dadosAdicionais: {
          pacienteId: {
            label: 'Paciente',
            type: 'autocomplete',
            itemLabel: 'nome',
            lookup: LookupsConfigs.paciente(),
          },
          profissionalId: {
            label: 'Profissional',
            type: 'autocomplete',
            itemLabel: 'nome',
            lookup: LookupsConfigs.user.profissionais({
              onModelChange(value) {
                $form.value.model.dadosAdicionais.procedimentoId = null;
              },
            }),
            layout: {
              sm: 6,
            },
          },
          procedimentoId: {
            label: 'Procedimento',
            type: 'autocomplete',
            itemLabel: 'nome',
            disabled: !model.dadosAdicionais.profissionalId,
            hidden: !!$agendamento.value,
            lookup: LookupsConfigs.procedimento({
              profissionalId: model.dadosAdicionais.profissionalId!,
              noCache: true,
            }),
            layout: {
              sm: 6,
            },
          },
          convenioId: {
            label: 'Convênio',
            type: 'autocomplete',
            itemLabel: 'nome',
            lookup: LookupsConfigs.convenio(),
            layout: {
              sm: 6,
            },
          },
        },
      };
    },
    async loadEditCallback({ id, setFormModel }) {
      const transacao = await TransacaoService.getById(id);

      setTransacao(transacao);

      if (!transacao) return redirectError(404);

      setFormModel({
        geral: {
          descricao: transacao.descricao,
          valor: transacao.valor,
          contaFinanceiraId: transacao.contaFinanceira?.id,
          categoriaId: transacao.categoria?.id,
          vencimento: transacao.vencimento,
          pago: transacao.pago,
          dataPagamento: transacao.dataPagamento,
          formaPagamento: transacao.formaPagamento,
          centroCustoId: transacao.centroCusto?.id,
        },
        dadosAdicionais: {
          profissionalId: transacao.profissional?.id,
          procedimentoId: transacao.procedimento?.id,
          pacienteId: transacao.paciente?.id,
          convenioId: transacao.convenio?.id,
        },
        repeticao: {
          geral: {
            repetir: false,
            modo: null,
            recorrencia: transacao.recorrencia && {
              id: transacao.recorrencia.id,
              index: transacao.recorrenciaIndex,
              numero: transacao.recorrencia.numero,
            },
          },
          repeticoes: {
            tipoRepeticao: null,
            numeroRepeticoes: null,
          },
          parcelas: {
            numeroParcelas: null,
            valorEntrada: null,
            valorParcela: null,
          },
        },
        agendamento:
          !transacao.recorrencia && transacao.agendamento
            ? {
                desconto: transacao.agendamento.desconto,
                procedimentos: [],
              }
            : null,
      });
    },
    mapCustomErrors: () => $repeticaoErrors.value,
  });

  return {
    ...formConfig,
    $repeticaoErrors,
    setRepeticaoErrors,
  };
}

function useActions({
  $form,
  isEdit,
}: {
  $form: Ref<IForm<ITransacaoFormModel>>;
  isEdit: boolean;
}) {
  watchRun(() => $form.value.model.geral.pago, handlePagoChanged);
  loadDefaults();

  const $recorrencia = computed(
    () => $form.value.model.repeticao.geral.recorrencia,
  );

  function handlePagoChanged(pago: boolean) {
    if ($form.value.loading) return;

    $form.value.model.geral.dataPagamento = pago
      ? DateHelpers.today().toSQLDate()
      : null;
  }

  function handleProcedimentosChange(
    procedimentos: IAgendamentoRecebimentoProcedimentoModel[],
  ) {
    if (!$recorrencia.value && $form.value.model.agendamento) {
      $form.value.model.agendamento.procedimentos = procedimentos;
    }
  }

  function handleDescontoChange(desconto: number) {
    if (!$recorrencia.value && $form.value.model.agendamento) {
      $form.value.model.agendamento.desconto = desconto;
    }
  }

  function handleValorTotalChange(valorTotal: number) {
    if (!$recorrencia.value) {
      $form.value.model.geral.valor = valorTotal;
    }
  }

  async function loadDefaults() {
    if (!isEdit) {
      const configuracaoFinanceira =
        await ConfiguracaoGraphql.configuracaoFinanceira();

      if (configuracaoFinanceira) {
        const { formaPagamento, contaFinanceira, centroCusto } =
          configuracaoFinanceira;

        $form.value.model.geral.formaPagamento = formaPagamento;

        $form.value.model.geral.contaFinanceiraId = contaFinanceira?.id;

        $form.value.model.geral.centroCustoId = centroCusto?.id;
      }
    }
  }

  return {
    handleProcedimentosChange,
    handleDescontoChange,
    handleValorTotalChange,
  };
}

interface ITransacaoFormProps {
  form: IForm<ITransacaoFormModel>;
  schema: ITransacaoFormSchema;
}

const GeralFields = createComponent<
  ITransacaoFormProps & {
    geralTitle: string;
  }
>({
  name: 'TransacaoFormGeralFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
    geralTitle: { type: String, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection title={props.geralTitle}>
        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema.geral}
          v-model={props.form.model.geral}
        />
      </PageSection>
    );
  },
});

const DadosAdicionaisFields = createComponent<ITransacaoFormProps>({
  name: 'TransacaoFormDadosAdicionaisFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection title="Dados adicionais" divider>
        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema.dadosAdicionais}
          v-model={props.form.model.dadosAdicionais}
        />
      </PageSection>
    );
  },
});

const ProcedimentosTable = createComponent<{
  agendamento: ITransacaoFragment_agendamento | null | undefined;
  recorrencia: IRecorrenciaModel | null | undefined;
  handleProcedimentosChange: (
    procedimentos: IAgendamentoRecebimentoProcedimentoModel[],
  ) => void;
  handleDescontoChange: (desconto: number) => void;
  handleValorTotalChange: (valorTotal: number) => void;
}>({
  name: 'TransacaoFormDadosProcedimentosTable',
  props: {
    agendamento: { type: Object },
    recorrencia: { type: Object },
    handleProcedimentosChange: { type: Function, required: true },
    handleDescontoChange: { type: Function, required: true },
    handleValorTotalChange: { type: Function, required: true },
  },
  setup(
    {
      agendamento,
      recorrencia,
      handleProcedimentosChange,
      handleDescontoChange,
      handleValorTotalChange,
    },
    ctx,
  ) {
    return () =>
      agendamento && (
        <PageSection divider>
          <FormHeader title="Procedimentos" class="pb-4" />

          <AgendamentoProcedimentosTable
            agendamento={agendamento}
            mode={
              recorrencia
                ? AgendamentoViewProcedimentoTableMode.VALORES
                : AgendamentoViewProcedimentoTableMode.EDIT
            }
            onProcedimentosChange={handleProcedimentosChange}
            onDescontoChange={handleDescontoChange}
            onValorTotalChange={handleValorTotalChange}
          />
        </PageSection>
      );
  },
});

import { QueryGraphql } from '@/graphql/query';
import {
  getTransacaoSaldo,
  getTransacoesItems,
} from '@/lib/helpers/models/transacao';
import {
  IExtratoItem,
  ITransacaoItem,
  ITransacoesQuery,
  ITransacoesQueryVariables,
  ITransacoesSummaryQuery,
  ITransacoesSummaryQueryVariables,
  TransacaoOrderBy,
  TransacaoTipo,
  TransacaoWhereInput,
} from '@/typings';
import { computed, Ref } from '@vue/composition-api';
import { useValue } from '..';
import { useQueryConfig } from '../useQueryConfig';

export function useTransacoesPage(tipo: TransacaoTipo | null = null) {
  const [$where, handleFilter] = useValue<TransacaoWhereInput | null>(null);

  const $isExtrato = computed(() => !tipo);

  const $dates = computed(() => {
    const where = $where.value;

    if (!where || !where.dataExtrato_gte || !where.dataExtrato_lte) {
      return null;
    }

    return {
      startDate: where.dataExtrato_gte,
      endDate: where.dataExtrato_lte,
    };
  });

  const { $data: $items, $loading: $itemsLoading } = useTransacoesQuery({
    $where,
    $isExtrato,
  });

  const { $data: $summary, $loading: $summaryLoading } =
    useTransacoesSummaryQuery($where);

  return {
    $where,
    handleFilter,
    $isExtrato,
    $dates,
    $items,
    $itemsLoading,
    $summary,
    $summaryLoading,
  };
}

function useTransacoesQuery({
  $where,
  $isExtrato,
}: {
  $where: Ref<TransacaoWhereInput | null>;
  $isExtrato: Ref<boolean>;
}) {
  return useQueryConfig<
    ITransacoesQuery,
    ITransacaoItem[] | IExtratoItem[],
    ITransacoesQueryVariables
  >({
    query: QueryGraphql.TransacoesQuery,
    variables: () => ({
      where: $where.value,
      orderBy: [TransacaoOrderBy.dataExtrato_ASC],
    }),
    mapData(result) {
      const items: ITransacaoItem[] = getTransacoesItems(result);
      if (!$isExtrato.value) {
        return items;
      }

      const extratoItems: IExtratoItem[] = items.map((v, idx, items) => ({
        ...v,
        saldo: getTransacaoSaldo({ idx, items }),
      }));

      return extratoItems;
    },
    enabledFn: () => !!$where.value,
    debounce: 500,
    fetchPolicy: 'network-only',
  });
}

function useTransacoesSummaryQuery($where: Ref<TransacaoWhereInput | null>) {
  return useQueryConfig<
    ITransacoesSummaryQuery,
    ITransacoesSummaryQuery['transacoesSummary'] | null,
    ITransacoesSummaryQueryVariables
  >({
    query: QueryGraphql.TransacoesSummaryQuery,
    variables: () => ({ where: $where.value }),
    mapData: result => result?.transacoesSummary || null,
    enabledFn: () => !!$where.value,
    debounce: 500,
    fetchPolicy: 'network-only',
  });
}

import { PagePanel } from '@/components/page/PagePanel';
import { ConfiguracaoGraphql } from '@/graphql/configuracao/ConfiguracaoGraphql';
import { useValue } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import {
  ITransacaoFilter,
  TransacaoTipo,
  TransacaoWhereInput,
} from '@/typings';
import { computed, onMounted, SetupContext } from '@vue/composition-api';
import { TransacaoFiltersFields } from './TransacaoFiltersFields';

interface IProps {
  tipo?: TransacaoTipo | null;
}

interface IEvents {
  onInput: (where: TransacaoWhereInput) => void;
}

export const TransacaoFilters = createComponent<IProps, IEvents>({
  name: 'TransacaoFilters',
  props: {
    tipo: { type: String as any, default: null },
  },
  setup(props, ctx) {
    const [$filters] = useValue<ITransacaoFilter[]>(['Data', 'Conta']);

    const $items = useItems(props);

    const { handleFilterChange, $contaFinanceiraId } = useEvents(ctx);

    return () => (
      <PagePanel title="Filtros" class="z-5">
        <v-select
          multiple
          chips
          deletableChips
          solo
          hideDetails
          label="Filtrar por"
          class="mb-4"
          prependInnerIcon={MyIcons.filter}
          items={$items.value}
          v-model={$filters.value}
        />

        <TransacaoFiltersFields
          filters={$filters.value}
          tipo={props.tipo}
          contaFinanceiraId={$contaFinanceiraId.value}
          onInput={handleFilterChange}
        />
      </PagePanel>
    );
  },
});

function useItems(props: IProps) {
  return computed(() => {
    const items = [
      { header: 'Selecionar campos a serem filtrados' },
      'Data',
      'Descrição',
      'Categoria',
      'Centro de custo',
      'Conta',
      'Convênio',
      'Forma de pagamento',
      'Paciente',
      'Procedimento',
      'Profissional',
      'Status',
    ];

    if (!props.tipo) {
      items.push('Tipo');
    }

    return items;
  });
}

function useEvents(ctx: SetupContext) {
  const [$contaFinanceiraId, setContaFinanceiraId] = useValue<string | null>(
    null,
  );

  function handleFilterChange(where: TransacaoWhereInput) {
    if ($contaFinanceiraId.value) {
      emitInput(where);
    }
  }

  function emitInput(where: TransacaoWhereInput) {
    ctx.emit('input', where);
  }

  onMounted(async () => {
    // load defaults
    const configuracaoFinanceira =
      await ConfiguracaoGraphql.configuracaoFinanceira();

    if (configuracaoFinanceira) {
      const { contaFinanceira } = configuracaoFinanceira;

      setContaFinanceiraId(contaFinanceira?.id);
    }
  });

  return {
    handleFilterChange,
    $contaFinanceiraId,
  };
}

import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { DataTable } from '@/components/datatable/DataTable';
import { useState } from '@/lib/composables';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { formatMoney } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { mapTransacaoItem } from '@/lib/helpers/models/transacao';
import { FinancasDashboardService } from '@/lib/services/financas/financasDashboard.service';
import { createComponent } from '@/lib/vue';
import { TransacaoDescricaoTd } from '@/modules/financas/transacoes/components/table/TransacaoDescricaoTd';
import { Routes } from '@/routes/routes';
import { IDataTableHeader, ITransacaoFragment, TransacaoTipo } from '@/typings';
import { computed } from '@vue/composition-api';
import { PropType } from 'vue';

interface IProps {
  tipo: TransacaoTipo;
}
export const TransacoesPendentesCard = createComponent<IProps>({
  name: 'TransacoesPendentesCard',
  props: {
    tipo: { type: String as PropType<TransacaoTipo>, required: true },
  },
  setup(props, ctx) {
    const { title, color, $state, $total, noDataText } = useComputed(props);

    const headers = useHeaders(color);

    const { handleRowClick, handleUpdate } = useEvents(props);

    return () => {
      const { data, loading } = $state.value;

      return (
        <DashboardCard
          title={title}
          updateAction={handleUpdate}
          loading={loading}
        >
          <div class="flex flex-col w-full">
            <div class="flex items-center my-1 space-x-2">
              <span class="text-subtitle text-coolGray-500">
                Total no período:
              </span>

              <span class={[color, 'text-title']}>
                {formatMoney($total.value)}
              </span>
            </div>

            <DataTable
              headers={headers}
              items={data}
              noDataText={noDataText}
              clickableRow
              class="w-full"
              onRowClick={handleRowClick}
              sortBy="vencimento"
            />
          </div>
        </DashboardCard>
      );
    };
  },
});

function useComputed(props: IProps) {
  const isReceita = props.tipo === TransacaoTipo.RECEITA;

  const label = isReceita ? 'Receitas' : 'Despesas';

  const $state = useState(s =>
    isReceita
      ? s.financas.dashboard.receitasPendentes
      : s.financas.dashboard.despesasPendentes,
  );

  const title = `${label} pendentes no período`;

  const noDataText = `Nenhuma ${label.toLowerCase()} neste período`;

  const color = isReceita ? 'text-green-600' : 'text-red-500';

  const $total = computed(() =>
    $state.value.data.reduce(
      (total, transacao) => (total += transacao.valor),
      0,
    ),
  );

  return { $state, color, title, noDataText, $total };
}

function useHeaders(color: string): IDataTableHeader<ITransacaoFragment>[] {
  return [
    {
      text: 'Vencimento',
      value: 'vencimento',
      mapValue: v => DateHelpers.formatDate(v.vencimento),
      width: 120,
    },
    {
      text: 'Descrição',
      value: 'descricao',
      slot: ({ header, item }) => [
        <TransacaoDescricaoTd
          header={header}
          item={mapTransacaoItem(item)}
          noCircle
          singleLine
        />,
      ],
      sortable: false,
    },
    {
      text: 'Valor',
      value: 'valor',
      align: 'center',
      money: true,
      valueAlign: 'end',
      width: 100,
      class: color,
    },
  ];
}

function useEvents(props: IProps) {
  const isReceita = props.tipo === TransacaoTipo.RECEITA;

  function handleRowClick(v: ITransacaoFragment) {
    const route = isReceita
      ? Routes.app.financas.receitas.edit(v.id, TransacaoTipo.RECEITA)
      : Routes.app.financas.despesas.edit(v.id, TransacaoTipo.DESPESA);

    return useRouter().push(route);
  }

  function handleUpdate() {
    if (isReceita) return FinancasDashboardService.receitasPendentes();

    return FinancasDashboardService.despesasPendentes();
  }

  return { handleRowClick, handleUpdate };
}

import { FinancasDashboardGraphql } from '@/graphql/financas/dashboard/FinancasDashboardGraphql';
import { Validate } from '@/lib/helpers/error';
import { store } from '@/store';
import { FinancasDashboardState } from '@/store/modules/financas/financasDashboard.store';
import { IFinancasDashboardPageQueryVariables } from '@/typings';

export const FinancasDashboardService = {
  async init() {
    FinancasDashboardState.setPeriodo(null);

    FinancasDashboardService.loadPage();
  },

  async loadPage(refetch = false) {
    FinancasDashboardState.setLoadingAll();

    const data = await FinancasDashboardGraphql.financasDashboardPage(
      getParams(),
      refetch,
    );

    FinancasDashboardState.loadPageQuery(data);
  },

  async contasFinanceirasSaldo() {
    FinancasDashboardState.setContasFinanceirasSaldo({ loading: true });

    const data = await FinancasDashboardGraphql.contasFinanceirasSaldo(true);

    FinancasDashboardState.setContasFinanceirasSaldo({ data });
  },

  async transacoesPeriodo() {
    FinancasDashboardState.setTransacoesPeriodo({ loading: true });

    const data = await FinancasDashboardGraphql.transacoesPeriodo(
      getParams(),
      true,
    );

    FinancasDashboardState.setTransacoesPeriodo({ data });
  },

  async receitasPorConvenios() {
    FinancasDashboardState.setReceitasPorConvenios({ loading: true });

    const data = await FinancasDashboardGraphql.receitasPorConvenios(
      getParams(),
      true,
    );

    FinancasDashboardState.setReceitasPorConvenios({ data });
  },

  async receitasPorProcedimentos() {
    FinancasDashboardState.setReceitasPorProcedimentos({ loading: true });

    const data = await FinancasDashboardGraphql.receitasPorProcedimentos(
      getParams(),
      true,
    );

    FinancasDashboardState.setReceitasPorProcedimentos({ data });
  },

  async despesasPendentes() {
    FinancasDashboardState.setDespesasPendentes({ loading: true });

    const data = await FinancasDashboardGraphql.despesasPendentes(
      getParams(),
      true,
    );

    FinancasDashboardState.setDespesasPendentes({ data });
  },

  async receitasPendentes() {
    FinancasDashboardState.setReceitasPendentes({ loading: true });

    const data = await FinancasDashboardGraphql.receitasPendentes(
      getParams(),
      true,
    );

    FinancasDashboardState.setReceitasPendentes({ data });
  },
};

function getParams(): IFinancasDashboardPageQueryVariables {
  const { periodo } = store.state.financas.dashboard.header;

  return {
    dataInicial: Validate.require(periodo?.startDate, 'dataInicial'),
    dataFinal: Validate.require(periodo?.endDate, 'dataFinal'),
  };
}

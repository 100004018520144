import { Td } from '@/components/datatable/columns/Td';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { TransacaoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader, IExtratoItem, ITransacaoItem } from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {
  header: IDataTableHeader;
  item: IExtratoItem | ITransacaoItem;
}

export const TransacaoPagoTd = createComponent<IProps>({
  name: 'TransacaoPagoTd',
  props: {
    header: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  setup(props, ctx) {
    async function handleClick() {
      return TransacaoService.updateTransacaoStatus({
        id: props.item.id,
        pago: !props.item.pago,
      });
    }

    const { $tooltip, $color, $disabled } = useComputeds(props);

    return () => (
      <Td id="TransacaoPagoTd" header={props.header} item={props.item}>
        <v-btn
          small
          outlined
          rounded
          aria-label={$tooltip.value}
          data-balloon-pos="up"
          disabled={$disabled.value}
          color={$color.value}
          onClick={handleClick}
        >
          {props.item.pagoStatus}
        </v-btn>
      </Td>
    );
  },
});

export default TransacaoPagoTd;

function useComputeds(props: IProps) {
  const $color = computed(() => {
    switch (props.item.pagoStatus) {
      case 'Pago':
        return MyTheme.green600;

      case 'Vencido':
        return MyTheme.red600;

      case 'A Vencer':
      default:
        return null;
    }
  });

  const $disabled = computed(
    () => props.item.saldoInicial || props.item.transferencia,
  );

  const $tooltip = computed(() => {
    if ($disabled.value) return null;

    const status = props.item.pago ? 'Não pago' : 'Pago';

    return `Marcar como ${status}`;
  });

  return { $tooltip, $color, $disabled };
}

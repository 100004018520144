import { Td } from '@/components/datatable/columns/Td';
import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { TransacaoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { FinancasButtons } from '@/modules/financas/components/FinancasButtons';
import {
  IExtratoItem,
  IIndexDataTableActions,
  ITransacaoItem,
  IDataTableHeader,
  TransacaoTipo,
} from '@/typings';
import { computed } from '@vue/composition-api';
import { RawLocation } from 'vue-router';
import { TransacaoDescricaoTd } from './TransacaoDescricaoTd';
import { TransacaoPagoTd } from './TransacaoPagoTd';

interface IProps {
  items: IExtratoItem[] | ITransacaoItem[] | null;
  loading: boolean;
  editRouteFn?: (id: string, item: IExtratoItem) => string | RawLocation;
  tipo?: TransacaoTipo | null;
}

export const TransacaoTable = createComponent<IProps>({
  name: 'TransacaoTable',
  props: {
    items: { type: Array },
    loading: { type: Boolean, required: true },
    editRouteFn: { type: Function },
    tipo: { type: String as any },
  },
  setup(props, ctx) {
    const { $actions } = useComputeds(props);

    const $headers = useHeaders(props);

    return () => (
      <IndexDataTable
        noSearch
        headers={$headers.value}
        items={props.items}
        loading={props.loading}
        actions={$actions.value}
      >
        <FinancasButtons slot="actions" tipo={props.tipo} />
      </IndexDataTable>
    );
  },
});

function useComputeds(props: IProps) {
  const $actions = computed<IIndexDataTableActions>(() => ({
    editRoute: props.editRouteFn,
    removeMany: TransacaoService.deleteMany,
    selectedMenus: [
      {
        text: 'Marcar como paga',
        icon: MyIcons.transacaoPaid,
        action: (ids: string[]) =>
          TransacaoService.updateManyTransacoesStatus({ ids, pago: true }),
      },
      {
        text: 'Marcar como não paga',
        icon: MyIcons.transacaoNotPaid,
        action: (ids: string[]) =>
          TransacaoService.updateManyTransacoesStatus({ ids, pago: false }),
      },
    ],
  }));

  return { $actions };
}

function useHeaders(props: IProps) {
  const $isExtrato = computed(() => !props.tipo);

  return computed(() => {
    const headers: Array<IDataTableHeader<IExtratoItem>> = [
      {
        text: 'Data',
        value: 'data',
        mapValue: v => DateHelpers.formatDate(v.data),
        width: 80,
        sortable: false,
      },
      {
        text: 'Descrição',
        value: 'descricao',
        slot: ({ header, item }) => [
          <TransacaoDescricaoTd
            header={header}
            item={item}
            noCircle={!$isExtrato.value}
          />,
        ],
        sortable: false,
      },
      {
        text: 'Categoria',
        value: 'categoria',
        mapValue: v => v.categoria,
        sortable: false,
      },
      {
        text: 'Valor',
        value: 'valor',
        slot: ({ header, item }) => [
          <Td header={header} item={item} class={valorClasses(item)} />,
        ],
        align: 'center',
        money: true,
        valueAlign: 'end',
        width: 100,
        sortable: false,
      },
      {
        text: 'Status',
        value: 'status',
        slot: ({ header, item }) => [
          <TransacaoPagoTd header={header} item={item} />,
        ],
        align: 'center',
        width: 90,
        sortable: false,
      },
    ];

    if ($isExtrato.value) {
      headers.push({
        text: 'Saldo',
        value: 'saldo',
        slot: ({ header, item }) => [
          <Td header={header} item={item} class={saldoClasses(item)} />,
        ],
        align: 'center',
        valueAlign: 'end',
        money: true,
        width: 100,
        sortable: false,
      });
    }

    return headers;
  });
}

function valorClasses(item: IExtratoItem | ITransacaoItem) {
  return {
    'text-red-500': item.tipo === TransacaoTipo.DESPESA,
    'text-green-600': item.tipo === TransacaoTipo.RECEITA,
  };
}

function saldoClasses({ saldo = 0 }: IExtratoItem) {
  return {
    'text-indigo-700': saldo >= 0,
    'text-red-600': saldo < 0,
  };
}

import { FormFields } from '@/components/form/fields/FormFields';
import { MyPage } from '@/components/page/MyPage';
import { useState } from '@/lib/composables';
import { dateRangePresetsDashboard } from '@/lib/constants/dateRangePresets';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { FinancasDashboardService } from '@/lib/services/financas/financasDashboard.service';
import { createComponent } from '@/lib/vue';
import { FinancasDashboardState } from '@/store/modules/financas/financasDashboard.store';
import { IFinancasDashboardHeader, IFormSchema } from '@/typings';
import { computed } from '@vue/composition-api';

export const FinancasDashboardHeader = createComponent({
  name: 'FinancasDashboardHeader',
  setup(props, ctx) {
    const $schema = useSchema();

    const { $model, handleModelChange } = useModel();

    const { handleRefresh } = useActions();

    return () => (
      <MyPage title="Resumo Financeiro" class="w-full">
        <div class="flex flex-wrap items-center py-6 pl-2 pr-8">
          <FormFields
            schema={$schema.value}
            value={$model.value}
            onInput={handleModelChange}
          />

          <v-btn text color="accent" onClick={handleRefresh} class="mb-2">
            <v-icon>{MyIcons.refresh}</v-icon>
            Atualizar
          </v-btn>

          <v-spacer />

          <FinancasShortcuts />
        </div>
      </MyPage>
    );
  },
});

function useSchema() {
  const $periodo = useState(s => s.financas.dashboard.header.periodo);

  return computed<IFormSchema<IFinancasDashboardHeader>>(() => ({
    periodo: {
      label: 'Período',
      type: 'date-range',
      hideDetails: true,
      presets: dateRangePresetsDashboard,
      layout: { width: 300, marginBottom: 4 },
      max: DateHelpers.parse($periodo.value?.startDate)?.plus({ months: 12 }),
      min: DateHelpers.parse($periodo.value?.endDate)?.minus({ months: 12 }),
    },
  }));
}

function useModel() {
  const $model = useState(s => s.financas.dashboard.header);

  function handleModelChange({ periodo }: IFinancasDashboardHeader) {
    FinancasDashboardState.setPeriodo(periodo);

    FinancasDashboardService.loadPage();
  }

  return { $model, handleModelChange };
}

function useActions() {
  FinancasDashboardService.init();

  return {
    handleRefresh() {
      FinancasDashboardService.loadPage(true);
    },
  };
}

const FinancasShortcuts = createComponent({
  name: 'FinancasShortcuts',
  setup(props, ctx) {
    return () => (
      <div class="flex flex-col ml-3 justify-end flex-initial">
        <h3 class="mb-2 font-medium text-gray-400 uppercase text-body-sm">
          Acesso rápido
        </h3>

        <div class="flex pl-3 space-x-2">
          <v-btn
            icon
            color="success"
            outlined
            exact
            to="/financas/receitas/new"
            aria-label="Nova Receita"
            data-balloon-pos="down"
          >
            <v-icon>{MyIcons.receita}</v-icon>
          </v-btn>

          <v-btn
            icon
            color={MyTheme.red500}
            outlined
            exact
            to="/financas/despesas/new"
            aria-label="Nova Despesa"
            data-balloon-pos="down"
          >
            <v-icon>{MyIcons.despesa}</v-icon>
          </v-btn>

          <v-btn
            icon
            color="secondary"
            outlined
            exact
            to="/financas/transferencia"
            aria-label="Transferência"
            data-balloon-pos="down"
          >
            <v-icon>{MyIcons.transferencia}</v-icon>
          </v-btn>
        </div>
      </div>
    );
  },
});

import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { useTransacoesPage } from '@/lib/composables/transacao/useTransacoesPage';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { TransacaoTipo } from '@/typings';
import { TransacaoFilters } from '../../components/filters/TransacaoFilters';
import { TransacaoSummary } from '../../components/summary/TransacaoSummary';
import { TransacaoTable } from '../../components/table/TransacaoTable';

export default createComponent({
  name: 'DespesasPage',
  setup(props, ctx) {
    const tipo = TransacaoTipo.DESPESA;

    const {
      handleFilter,
      $items,
      $itemsLoading,
      $summaryLoading,
      $summary,
      $dates,
    } = useTransacoesPage(tipo);

    const editRouteFn = (despesaId: string) =>
      Routes.app.financas.despesas.edit(despesaId, tipo);

    return () => (
      <MyPage title="Despesas">
        <TransacaoFilters tipo={tipo} onInput={handleFilter} />

        <PageSection>
          <FormHeader title="Despesas" />

          <TransacaoTable
            items={$items.value}
            loading={$itemsLoading.value}
            editRouteFn={editRouteFn}
            tipo={tipo}
          />
        </PageSection>

        <TransacaoSummary
          dates={$dates.value}
          loading={$summaryLoading.value}
          summary={$summary.value}
          tipo={tipo}
        />
      </MyPage>
    );
  },
});

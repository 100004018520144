import { createComponent } from '@/lib/vue';
import { TransacaoTipo } from '@/typings';
import { ContasFinanceirasCard } from '../components/dashboard/cards/ContasFinanceirasCard';
import { ReceitasPorConveniosCard } from '../components/dashboard/cards/ReceitasPorConveniosCard';
import { ReceitasPorProcedimentosCard } from '../components/dashboard/cards/ReceitasPorProcedimentosCard';
import { TransacoesPendentesCard } from '../components/dashboard/cards/TransacoesPendentesCard';
import { TransacoesPeriodoCard } from '../components/dashboard/cards/TransacoesPeriodoCard';
import { FinancasDashboardHeader } from '../components/dashboard/FinancasDashboardHeader';

export default createComponent({
  name: 'FinancasDashboardPage',
  setup(props, ctx) {
    return () => (
      <div class="flex flex-col space-y-6">
        <FinancasDashboardHeader />

        <ContasFinanceirasCard />

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <ReceitasPorConveniosCard class="w-full" />

          <ReceitasPorProcedimentosCard class="w-full" />
        </div>

        <TransacoesPeriodoCard />

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <TransacoesPendentesCard
            tipo={TransacaoTipo.DESPESA}
            class="w-full"
          />

          <TransacoesPendentesCard
            tipo={TransacaoTipo.RECEITA}
            class="w-full"
          />
        </div>
      </div>
    );
  },
});

import { LineChart } from '@/components/charts/LineChart';
import { DashboardCard } from '@/components/dashboard/DashboardCard';
import { useState } from '@/lib/composables';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { FinancasDashboardService } from '@/lib/services/financas/financasDashboard.service';
import { createComponent } from '@/lib/vue';
import {
  IFinancasDashboardState,
  IFinancasDashboardTransacoesPeriodo,
  ILineChartData,
  TransacaoTipo,
} from '@/typings';
import { computed, ComputedRef } from '@vue/composition-api';

export const TransacoesPeriodoCard = createComponent({
  name: 'TransacoesPeriodoCard',
  setup(props, ctx) {
    const $state = useState(s => s.financas.dashboard);

    const $chartData = useChartData($state);

    return () => {
      const { data, loading } = $state.value.transacoesPeriodo;

      return (
        <DashboardCard
          title="Receitas x Despesas"
          updateAction={FinancasDashboardService.transacoesPeriodo}
          noData={data.length === 0}
          loading={loading}
        >
          <LineChart
            value={$chartData.value}
            yScale={{
              title: 'Valor',
              beginAtZero: true,
              precision: 2,
              format: { style: 'currency', currency: 'BRL' },
            }}
            xScale={{ title: 'Período' }}
            tooltipFormat={{ money: true }}
          />
        </DashboardCard>
      );
    };
  },
});

function useChartData($state: ComputedRef<IFinancasDashboardState>) {
  return computed<ILineChartData[]>(() => {
    const { periodo } = $state.value.header;

    const { data } = $state.value.transacoesPeriodo;

    return [
      {
        label: 'Receitas',
        data: ChartHelpers.line.mapDates({
          startDate: periodo?.startDate,
          endDate: periodo?.endDate,
          data: mapData({ data, tipo: TransacaoTipo.RECEITA }),
        }),
        color: MyTheme.green500,
      },
      {
        label: 'Despesas',
        data: ChartHelpers.line.mapDates({
          startDate: periodo?.startDate,
          endDate: periodo?.endDate,
          data: mapData({ data, tipo: TransacaoTipo.DESPESA }),
        }),
        color: MyTheme.red500,
      },
    ];
  });

  function mapData({
    data,
    tipo,
  }: {
    data: IFinancasDashboardTransacoesPeriodo[];
    tipo: TransacaoTipo;
  }) {
    return data
      .filter(f => f.tipo === tipo)
      .map(v => ({ label: v.data, value: v.valor }));
  }
}

import { watchRun } from '@/lib/vue';
import { SetupContext } from '@vue/composition-api';
import { useValue } from '..';

export interface INomeEvents {
  onNomeChanged: (nome: string | null) => void;
}

export function useNome({
  ctx,
  watchNome,
}: {
  ctx: SetupContext;
  watchNome?: () => string | null | undefined;
}) {
  const [$nome, setNome] = useValue<string | null | undefined>(null);

  watchRun($nome, emitNomeChanged);

  if (watchNome) {
    watchRun(watchNome, nome => {
      setNome(nome);
      emitNomeChanged(nome);
    });
  }

  function emitNomeChanged(nome: string | null | undefined) {
    ctx.emit('nomeChanged', nome || null);
  }

  return {
    $nome,
    setNome,
    emitNomeChanged,
  };
}

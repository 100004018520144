import { MyPage } from '@/components/page/MyPage';
import { useTransacaoFormPage } from '@/lib/composables/transacao/useTransacao';
import { useNome } from '@/lib/composables/utils/useNome';
import { createComponent } from '@/lib/vue';
import { TransacaoTipo } from '@/typings';
import { computed } from '@vue/composition-api';
import { TransacaoForm } from '../../components/forms/TransacaoForm';

export default createComponent({
  name: 'ReceitaFormPage',
  setup(props, ctx) {
    const tipo = TransacaoTipo.RECEITA;
    const { $nome, setNome } = useNome({ ctx });

    const { page, isEdit, $indexRoute, handleSubmit, handleDelete } =
      useTransacaoFormPage({ ctx, tipo });

    const $title = computed(() => {
      if ($nome.value) {
        return `Receita: ${$nome.value}`;
      }

      return isEdit ? 'Editar Receita' : 'Nova Receita';
    });

    return () => (
      <MyPage title={$title.value} form>
        <TransacaoForm
          page={page}
          tipo={tipo}
          indexRoute={$indexRoute.value}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </MyPage>
    );
  },
});

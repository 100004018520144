import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';

export default createComponent({
  name: 'FinancasSidebar',
  setup(props, ctx) {
    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={menus} />
      </ContextSidebar>
    );
  },
});

const menus: IMenu[] = [
  {
    text: 'Finanças',
    header: true,
  },
  {
    text: 'Resumo',
    icon: MyIcons.financasDashboard,
    to: Routes.app.financas.index,
  },
  {
    text: 'Fluxo de caixa',
    icon: MyIcons.fluxoCaixa,
    to: Routes.app.financas.fluxoCaixa,
  },
  {
    text: 'Transações',
    subheader: true,
  },
  {
    text: 'Extrato',
    icon: MyIcons.extrato,
    to: Routes.app.financas.extrato,
  },
  {
    text: 'Receitas',
    icon: MyIcons.receita,
    to: Routes.app.financas.receitas.index,
  },
  {
    text: 'Despesas',
    icon: MyIcons.despesa,
    to: Routes.app.financas.despesas.index,
  },
  {
    text: 'Relatórios',
    subheader: true,
  },
  {
    text: 'Análise de despesas',
    icon: MyIcons.analiseDespesas,
    to: Routes.app.financas.relatorios.despesas,
  },
  {
    text: 'Análise de receitas',
    icon: MyIcons.analiseReceitas,
    to: Routes.app.financas.relatorios.receitas,
  },
];

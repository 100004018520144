import { MyIcons } from '@/lib/helpers/MyIcons';
import { MyTheme } from '@/lib/helpers/MyTheme';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { TransacaoTipo } from '@/typings';
import { computed } from '@vue/composition-api';
import { RawLocation } from 'vue-router';

interface IProps {
  tipo?: TransacaoTipo | null;
}

export const FinancasButtons = createComponent<IProps>({
  name: 'FinancasButtons',
  props: {
    tipo: { type: String as any },
  },
  setup(props, ctx) {
    const $transferenciaTo = computed<RawLocation>(() =>
      Routes.app.financas.transferencia(props.tipo),
    );

    const $receitaTo = computed<RawLocation>(() =>
      Routes.app.financas.receitas.new(props.tipo),
    );

    const $despesaTo = computed<RawLocation>(() =>
      Routes.app.financas.despesas.new(props.tipo),
    );

    return () => (
      <div id="FinancasButtons" class="flex justify-end">
        <Transferencia to={$transferenciaTo.value} />

        <Receita to={$receitaTo.value} />

        <Despesa to={$despesaTo.value} />
      </div>
    );
  },
});

interface IFinancasButtonsProps {
  to: RawLocation;
}

const Transferencia = createComponent<IFinancasButtonsProps>({
  name: 'FinancasButtonsTransferencia',
  props: {
    to: { type: [Object, String], required: true },
  },
  setup(props, ctx) {
    return () => (
      <v-btn
        aria-label="Transferência"
        data-balloon-pos="up"
        icon
        exact
        to={props.to}
      >
        <v-icon>{MyIcons.transferencia}</v-icon>
      </v-btn>
    );
  },
});

const Receita = createComponent<IFinancasButtonsProps>({
  name: 'FinancasButtonsReceita',
  props: {
    to: { type: [Object, String], required: true },
  },
  setup(props, ctx) {
    return () => (
      <v-btn dark color="success" outlined exact to={props.to} class="mx-2">
        <v-icon left>{MyIcons.receita}</v-icon>
        Nova Receita
      </v-btn>
    );
  },
});

const Despesa = createComponent<IFinancasButtonsProps>({
  name: 'FinancasButtonsDespesa',
  props: {
    to: { type: [Object, String], required: true },
  },
  setup(props, ctx) {
    return () => (
      <v-btn dark color={MyTheme.red500} outlined exact to={props.to}>
        <v-icon left>{MyIcons.despesa}</v-icon>
        Nova Despesa
      </v-btn>
    );
  },
});

import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { ConstantsHelper } from '@/lib/constants/helper';
import { validationSummary } from '@/lib/form';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IForm,
  IFormValidationErrors,
  IRequireField,
  ITransacaoRepeticaoFormSchema,
  ITransacaoRepeticaoModel,
  TransacaoRepeticaoModo,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import round from 'lodash/round';
import { TransacaoRepeticaoPreview } from './TransacaoRepeticaoPreview';

interface IJsxProps {
  value?: ITransacaoRepeticaoModel;
  form: IForm<any>;
  valor?: number | null;
  vencimento?: string | null;
  isEdit: boolean;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (model: ITransacaoRepeticaoModel) => void;
  onValidate: (errors: IFormValidationErrors[]) => void;
}

export const TransacaoRepeticaoFields = createComponent<IJsxProps, IEvents>({
  name: 'TransacaoRepeticaoFields',
  props: {
    value: { type: Object, required: true },
    form: { type: Object, required: true },
    valor: { type: Number },
    vencimento: { type: String },
    isEdit: { type: Boolean, required: true },
  },
  setup(props: IProps, ctx) {
    const { $isParcelas, $isRepeticoes, $schema } = useComputeds(props);

    useEvents({ props, ctx, $isParcelas, $schema });

    return () => {
      if (props.isEdit) {
        return null;
      }

      return (
        <div>
          <PageSection divider title="Repetição">
            <div class="flex flex-col" slot="fields">
              <FormFields
                form={props.form}
                schema={$schema.value.geral}
                v-model={props.value.geral}
                class="mb-3"
              />

              <FormFields
                form={props.form}
                hide={!$isRepeticoes.value}
                schema={$schema.value.repeticoes}
                v-model={props.value.repeticoes}
              />

              <FormFields
                form={props.form}
                hide={!$isParcelas.value}
                schema={$schema.value.parcelas}
                v-model={props.value.parcelas}
              />
            </div>
          </PageSection>

          <TransacaoRepeticaoPreview
            repeticao={props.value}
            valor={props.valor}
            vencimento={props.vencimento}
          />
        </div>
      );
    };
  },
});

function useComputeds(props: IProps) {
  const $isParcelas = computed(
    () =>
      props.value.geral.repetir &&
      props.value.geral.modo === TransacaoRepeticaoModo.PARCELAS,
  );

  const $isRepeticoes = computed(
    () =>
      props.value.geral.repetir &&
      props.value.geral.modo === TransacaoRepeticaoModo.REPETICAO,
  );

  const $schema = computed<ITransacaoRepeticaoFormSchema>(() => ({
    geral: {
      repetir: {
        label: 'Repetir',
        type: 'switch',
        layout: { maxWidth: 160 },
      },
      modo: {
        label: 'Modo',
        type: 'select',
        items: ConstantsHelper.transacaoRepeticaoModos,
        hidden: !props.value.geral.repetir,
        hideDetails: true,
        layout: { maxWidth: 200 },
      },
    },
    repeticoes: {
      tipoRepeticao: {
        label: 'Tipo',
        type: 'select',
        items: ConstantsHelper.tiposRepeticoes,
        menuProps: { maxHeight: 200 },
        layout: { maxWidth: 200 },
      },
      numeroRepeticoes: {
        label: 'Número de repetições',
        type: 'number',
        integer: true,
        disabled: !$isRepeticoes.value,
        validations: {
          gt: 0,
          lte: 120,
        },
        layout: {
          maxWidth: 240,
        },
      },
    },
    parcelas: {
      valorEntrada: {
        label: 'Entrada de',
        type: 'money',
        layout: {
          maxWidth: 200,
        },
      },
      numeroParcelas: {
        label: 'Número de parcelas',
        type: 'number',
        integer: true,
        disabled: !$isParcelas.value,
        validations: {
          gt: 0,
          lte: 120,
        },
        layout: {
          maxWidth: 200,
        },
      },
    },
  }));

  return { $schema, $isParcelas, $isRepeticoes };
}

function useEvents({
  props,
  ctx,
  $isParcelas,
  $schema,
}: {
  props: IProps;
  ctx: SetupContext;
  $isParcelas: Ref<boolean>;
  $schema: Ref<ITransacaoRepeticaoFormSchema>;
}) {
  function emitInput(value: ITransacaoRepeticaoModel) {
    ctx.emit('input', value);

    emitValidate();
  }

  function emitValidate() {
    const errors = validationSummary({
      schema: $schema.value,
      model: props.value,
      form: props.form,
      customErrors: [],
    });

    ctx.emit('validate', errors);
  }

  function calcValorParcela() {
    const parcelas = props.value.parcelas;

    if (!$isParcelas.value || !parcelas.numeroParcelas) {
      return;
    }

    const valor = (props.valor || 0) - (parcelas.valorEntrada || 0);

    const valorParcela = valor / parcelas.numeroParcelas;

    parcelas.valorParcela = round(valorParcela, 2);
  }

  watchRun(() => props.form.submitted, emitValidate);
  watchRun(() => props.value, emitInput, { deep: true });
  watchRun(
    () => [
      props.valor,
      props.value.parcelas.valorEntrada,
      props.value.parcelas.numeroParcelas,
    ],
    calcValorParcela,
  );
}
